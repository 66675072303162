var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-text',[_c('v-card',{staticClass:"d-flex justify-space-between",attrs:{"outlined":""}},[(!_vm.editStatus)?_c('div',[(_vm.Lo_Question.status === 'Definitiv')?[_c('v-chip',{staticClass:"ma-1 mt-2",attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Staus: "+_vm._s(_vm.Lo_Question.status)+" ")],1)]:[_c('v-chip',{staticClass:"ma-1 mt-2",attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" Staus: "+_vm._s(_vm.Lo_Question.status)+" ")])]],2):_vm._e(),(_vm.editStatus)?_c('div',[_c('v-select',{staticClass:"pa-3",attrs:{"dense":"","items":_vm.La_Status,"label":"Status"},on:{"change":_vm.changeStaus}})],1):_vm._e(),_c('div',[(!_vm.editStatus)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,false,3138236686)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.editStatus)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editStatus = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,false,867554885)},[_c('span',[_vm._v("speicheren")])]):_vm._e()],1)]),_c('div',[_c('v-card',{staticClass:"pa-1 my-1",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[(!_vm.editNummer)?_c('div',{staticClass:"d-flex flex-row dense"},[(!_vm.editNummer)?_c('h5',[_vm._v("Nr.")]):_vm._e(),(!_vm.editNummer)?_c('h5',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.Lo_Question.nummer)}}):_vm._e()]):_vm._e(),(!_vm.editNummer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editNummer = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,false,3061626038)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.editNummer)?_c('h5',[_vm._v("Nr.")]):_vm._e(),(_vm.editNummer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editNummer = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,false,3194110269)},[_c('span',[_vm._v("speicheren")])]):_vm._e()],1),(_vm.editNummer)?_c('tiptap-vuetify',{attrs:{"extensions":_vm.NrExtensions},model:{value:(_vm.Lo_Question.nummer),callback:function ($$v) {_vm.$set(_vm.Lo_Question, "nummer", $$v)},expression:"Lo_Question.nummer"}}):_vm._e()],1)],1),_c('div',[_c('v-card',{staticClass:"pa-1 my-1",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" Rolle der Kandidatin / des Kandidaten: ")]),(!_vm.editRolle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editRolle = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,false,1843190274)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.editRolle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editRolle = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,false,4240429801)},[_c('span',[_vm._v("speicheren")])]):_vm._e()],1),_c('div',{staticClass:"d-flex justify-space-between"},[(!_vm.editRolle)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.Lo_Question.rolleDesKandidat)}}):_vm._e()]),(_vm.editRolle)?_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.Lo_Question.rolleDesKandidat),callback:function ($$v) {_vm.$set(_vm.Lo_Question, "rolleDesKandidat", $$v)},expression:"Lo_Question.rolleDesKandidat"}}):_vm._e()],1)],1),_c('div',[_c('v-card',{staticClass:"pa-1 my-1",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Ausgangssituation:")]),(!_vm.editAusgang)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editAusgang = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,false,3065835314)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.editAusgang)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editAusgang = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,false,758812761)},[_c('span',[_vm._v("speicheren")])]):_vm._e()],1),(!_vm.editAusgang)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.Lo_Question.ausgangssituation)}}):_vm._e(),(_vm.editAusgang)?_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.Lo_Question.ausgangssituation),callback:function ($$v) {_vm.$set(_vm.Lo_Question, "ausgangssituation", $$v)},expression:"Lo_Question.ausgangssituation"}}):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"my-4"}),_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" Fragen an den Kandidaten / an die Kandidatin: ")]),_vm._l((_vm.Lo_Question.fragen),function(frage,index){return _c('v-card',{key:index,staticClass:"pa-1 my-1",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h6',[_vm._v("Frage: "+_vm._s(index + 1))]),_c('div',[(!_vm.editFragen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editFragen = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.editFragen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.editFragen = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("speicheren")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"danger"},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])],1)]),(!_vm.editFragen)?_c('div',{domProps:{"innerHTML":_vm._s(frage.frage)}}):_vm._e(),(_vm.editFragen)?_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(frage.frage),callback:function ($$v) {_vm.$set(frage, "frage", $$v)},expression:"frage.frage"}}):_vm._e(),(!_vm.editFragen)?_c('div',{domProps:{"innerHTML":_vm._s(frage.ErwarteteAntworten)}}):_vm._e(),(_vm.editFragen)?_c('tiptap-vuetify',{staticClass:"my-1",attrs:{"extensions":_vm.extensions},model:{value:(frage.ErwarteteAntworten),callback:function ($$v) {_vm.$set(frage, "ErwarteteAntworten", $$v)},expression:"frage.ErwarteteAntworten"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Sie werden eine Frage Löschen! ")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v("Möchten Sie fortfahren?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Nein ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteItem(index)}}},[_vm._v(" Löschen ")])],1)],1)],1)],1)}),_c('base-hover-button',{attrs:{"color":"primary lighten-5","text-color":"primary--text","text":"Neue Frage","iconName":" mdi-plus"},nativeOn:{"click":function($event){return _vm.addItem()}}})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }